<template>
  <div>
    <!--               此文件为测试用，可删除              -->
    <!-- 卡片区域 -->
    <el-card>
      <!-- 填报 列表 -->
      <el-table :data="itemList" border stripe>
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column label="项目编号" prop="itemId"></el-table-column>
        <el-table-column label="项目名称" prop="itemName"></el-table-column>
        <el-table-column label="其他信息" ></el-table-column>
        <el-table-column label="操作" width="300px">
          <template v-slot="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              @click="WriteDialog(scope.row)"
            >填报</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 填报对话框  -->
      <el-dialog title="数据填报" v-model="WriteDialogVisible" width="50%" @close="WriteDialogClosed">
        <!-- 表单 -->
        <el-form ref="writeForm" :model="curItem" label-width="80px" width="100%">
          <el-form-item label="项目名称">
            <el-input v-model="curItem.itemName" readonly></el-input>
          </el-form-item>
          <!-- <el-form-item label="公式">
            <el-input readonly></el-input>
          </el-form-item> -->
          <el-form-item
            label="数量"
            prop="num"
            :rules="[{required: true, message: `数量不能为空`, trigger: 'blur'},{type:'number', message: `请输入数字`, trigger: 'blur'}]"
          >
            <el-input v-model.number="curItem.num"></el-input>
          </el-form-item>

          <el-form-item label="返工图片">
            <el-upload
              class="upload-demo"
              style="width:100%"
              action
              ref="upload"
              :auto-upload="false"
              :on-exceed="onExceed"
              :on-change="onChange"
              :http-request="HttpRequest"
              accept="image/jpeg, image/png, image/jpg"
              multiple
              :limit="3"
              :file-list="fileList"
              list-type="picture"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <template #tip>
                <div class="el-upload__tip">只能上传jpg/jpeg/png文件</div>
              </template>
            </el-upload>
          </el-form-item>
          <!-- <el-form-item style="padding-top:10px; width:360px;">
            <el-button type="primary" @click="submitForm">提交</el-button>
            <el-button>取消</el-button>
          </el-form-item>-->
        </el-form>
        <!-- 底部 -->
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="WriteDialogVisible = false">取消</el-button>
            <el-button type="primary" @click="WriteDialogVisible = false">确定</el-button>
          </span>
        </template>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      itemList: [{ itemId: "0001", itemName: "test", num: null, piclist: "" }],
      curItem: {},
      fileList: [],
      WriteDialogVisible: false,
      form: {
        //form里面的参数
        itemId: "",
        num: null,
        piclist: []
      }
    };
  },
  mounted() {
    this.getItemList();
  },
  methods: {
    // 获取要填报的项目列表
    getItemList() {},
    onExceed(files, fileList) {
      this.$message.warning("最多选择3张图片");
    },
    onChange(file, fileList) {
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (extension !== "jpg" && extension !== "png" && extension !== "jpeg") {
        this.$message.warning("只能上传后缀是.jpg .jpeg .png的文件");
        this.fileList = [];
        this.file = "";
        return false;
      }
      // this.file = file.raw;
      // console.log(fileList);
      this.fileList = fileList;
    },
    HttpRequest(file) {
      // 把图片数据 加入到表单中
      this.form.piclist = this.fileList;
      // 当前表单的所有数据  将被提交的数据
      let formData = this.form;
      let url = "#";
      let config = {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      };
      this.$http
        .post(url, formData, config)
        .then(res => {
          if (res.data.meta.status === 200) {
            this.$message.success("图片上传成功");
          } else {
            this.$message.error("上传失败：" + res.data.meta.msg);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    WriteDialog(item) {
      this.WriteDialogVisible = true;
      // console.log(item)
      this.curItem = item;
    },
    WriteDialogClosed() {
      this.$refs.writeForm.validate(async valid => {
        // 如果valid参数为true则验证通过
        if (!valid) {
          this.$message.warning("本次填报无效")
          return;
        }
        this.form.itemId = this.curItem.itemId;
        this.form.num = this.curItem.num;
        this.$refs.upload.submit();
        console.log(this.form);
      });
    }
  }
};
</script>

<style lang="less" scoped>
</style>